
.card-body {
  padding: 2rem; /* Keeps the padding comfortable */
}

@media (min-width: 768px) {
  .text-md-end {
    text-align: right; /* Ensures alignment to the right on medium screens and up */
  }
}

@media (max-width: 767px) {
  .col-md-4 {
    margin-top: 1rem; /* Adds some space above buttons on smaller screens */
    text-align: center; /* Center the buttons on small screens */
  }
}
.checkout-button {
  background-color: #28a745; /* Ensures it uses Bootstrap's success green */
  border-color: #28a745; /* Same as background color */
  color: white; /* Text color */
  font-size: 1.2rem; /* Slightly larger text */
  font-weight: 600; /* Makes the text bold */
  padding: 10px 20px; /* Increases the padding for a bigger button */
  border-radius: 8px; /* Rounds the corners more */
  transition: background-color 0.3s ease-in-out, transform 0.2s ease;
}

.checkout-button:hover {
  background-color: #218838; /* Darker green on hover */
  transform: scale(1.05); /* Slightly enlarges the button on hover */
}

.checkout-button:active {
  background-color: #1e7e34; /* Even darker green when clicked */
  transform: scale(0.98); /* Slightly shrinks the button when active */
}


@media (max-width: 768px) {
  .card {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 10px;
  }

  .card-body {
    padding: 10px;
  }

  h2 {
    font-size: 1.5em;
    margin-bottom: 15px;
    text-align: center;
  }

  table {
    width: 100%;
    font-size: 0.9em;
    border-collapse: collapse;
  }

  th {
    display: none; /* Hide table headers */
  }

  td {
    padding: 10px;
    text-align: center;
    border: none; /* Remove table borders */
  }

  img {
    width: 40px;
    height: 40px;
    margin-left: 0;
  }

  .btn {
    font-size: 0.9em;
    padding: 8px 12px;
  }
}

@media (max-width: 576px) {
  h2 {
    font-size: 1.2em;
  }

  td {
    font-size: 0.8em;
    padding: 8px;
    border: none; /* Ensure borders are removed on very small screens */
  }

  img {
    width: 35px;
    height: 35px;
  }

  .btn {
    font-size: 0.8em;
    padding: 6px 10px;
  }

  /* Stack columns for a better mobile layout */
  td:nth-of-type(2),
  td:nth-of-type(3),
  td:nth-of-type(4) {
    display: block;
    text-align: left;
    padding-left: 0;
  }

  td:nth-of-type(5) {
    text-align: center;
  }

  .btn.mt-3 {
    width: 100%;
    margin-top: 10px;
  }
}
@media (max-width: 576px) { /* For small screens like phones */
  .quantity-input {
    width: 100%; /* Full width on mobile */
    min-width: 60px;
  }
}

@media (min-width: 576px) { /* For medium screens and above */
  .quantity-input {
    width: 70px; /* Fixed size on larger screens */
  }
}
