/* Add some responsive styling */
@media (max-width: 576px) {
  .form-signin {
    padding: 1.5rem; /* Reduce padding on smaller screens */
    width: 100%; /* Ensure the form takes full width */
  }

  .form-signin h2 {
    font-size: 1.5rem; /* Adjust heading size */
  }

  .form-signin .form-control {
    font-size: 1rem; /* Adjust font size for inputs */
  }

  .form-signin .btn {
    font-size: 0.9rem; /* Slightly smaller buttons */
    padding: 0.6rem 1rem; /* Adjust button padding */
  }

  .form-signin p {
    font-size: 0.85rem; /* Adjust font size for links */
  }
}
.button-order{
  font-family: futura-pt,Tahoma,Geneva,Verdana,Arial,sans-serif;
  font-style: normal;
  font-size: .75rem;
  font-weight: 700;
  background-color: hsl(90, 65%, 48%);
  color: white;
  }
  .huh {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../images/landing.webp');
    background-size: cover;
    background-position: center;
  }

    
  