body {
  font-family: 'Arial', sans-serif;
  background-color: #f8f9fa;
  margin: 0;
  padding: 0;
}

.admin-dashboard {
  display: flex;
  height: 100vh;
  flex-direction: row; /* Default to row layout */
  overflow: hidden; /* Prevent content overflow */
}

@media (max-width: 768px) {
.admin-dashboard {
    flex-direction: column; /* Stack items vertically on smaller screens */
}
}


.navSidebar {
  width: 250px;
  background-color: #343a40;
  color: #fff;
  padding: 20px;
}

.navSidebar h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #ffc107;
}

.navSidebar nav ul {
  list-style: none;
  padding: 0;
}

.navSidebar nav ul li {
  margin-bottom: 15px;
}

.navSidebar nav ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.navSidebar nav ul li a:hover {
  color: #ffc107;
}

.navSidebar nav ul li a svg {
  margin-right: 10px;
  
}

.content {
  flex: 1;
  padding: 20px;
  background-color: #ffffff;
  overflow-y: auto;
}

.content h3 {
  font-size: 12px;
  color: #343a40;
  margin-bottom: 20px;
}

.summary-cards {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.card {
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.card h4 {
  font-size: 22px;
  color: #495057;
  margin-bottom: 10px;
}

.card p {
  font-size: 20px;
  color: #28a745;
  margin: 0;
}

.card ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.card ul li {
  font-size: 18px;
  color: #343a40;
  padding: 5px 0;
}

.card ul li:not(:last-child) {
  border-bottom: 1px solid #dee2e6;
}

.card ul li span {
  font-weight: bold;
}

@media (max-width: 768px) {
  .navSidebar nav ul li a {
    font-size: 14px; /* Smaller font size for mobile */
  }
  
  /* Icon size remains constant */
  .navSidebar nav ul li a svg {
    font-size: 18px; /* Set a fixed size for icons */
  }
  
  .navSidebar {
    width: 30%;
    height: auto;
  }

  .content {
    padding: 10px;
  }

  .summary-cards {
    flex-direction: column;
  }

  .card {
    width: 100%;
    margin-bottom: 20px;
  }
}