/* Home.css */

/* General Styling */
body {
    font-family: 'Roboto', sans-serif;
    background-color: #f7f7f7;
    color: #333;
  }
  
  
  
  header.nus .logo {
    width: 60px;
    height: auto;
  }
  
  header.nus .navbar {
    justify-content: flex-end;
  }
  
  header.nus .nav-link {
    font-size: 16px;
    font-weight: 500;
    transition: color 0.3s ease;
  }
  
  header.nus .nav-link:hover {
    color: #ffeb3b;
  }
  
  header.nus .icon {
    margin-right: 8px;
  }
  
  /* Search Section */
  h1 {
    color: #000000;
    font-weight: bold;
    margin-bottom: 30px;
  }
  
  .Form {
    margin-bottom: 20px;
  }
  
  .Form input[type="text"] {
    padding: 10px;
    border: 2px solid #0052cc;
    border-radius: 5px;
  }
  
  .Form button {
    padding: 10px 20px;
    background-color: #ffeb3b;
    color: #0052cc;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .Form button:hover {
    background-color: #ffd600;
  }
  
  /* Cards for Schools */
 
  /* Book List */
  h4 {
    color: #0052cc;
    font-weight: bold;
    margin-bottom: 40px;
  }
  
  .table thead {
    background-color: #0052cc;
    color: white;
  }
  
  .table tbody tr:hover {
    background-color: #f0f8ff;
  }
  
  .table tbody tr td {
    vertical-align: middle;
  }
  
  .table img {
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .table strong {
    color: #0052cc;
  }
  
  /* Add to Cart Button */
  .btn-success {
    background-color: #28a745;
    border: none;
    padding: 10px 30px;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .btn-success:hover {
    background-color: #218838;
  }
  
  /* Footer Styling */
  footer {
    
    color: white;
    padding: 15px 0;
    border-top: 5px solid #ffeb3b;
  }
  .complementary-title {
    font-weight: bold; /* Or any other style you want */
  }
  .checkout-button {
    background-color: #28a745; /* Ensures it uses Bootstrap's success green */
    border-color: #28a745; /* Same as background color */
    color: white; /* Text color */
    font-size: 1.2rem; /* Slightly larger text */
    font-weight: 600; /* Makes the text bold */
    padding: 10px 20px; /* Increases the padding for a bigger button */
    border-radius: 8px; /* Rounds the corners more */
    transition: background-color 0.3s ease-in-out, transform 0.2s ease;
  }
  .grades-container {
    padding: 15px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }

  .grade-button {
    transition: transform 0.2s ease, background-color 0.2s ease;
    font-size: 1rem;
    background-color: #fff9c4; /* Light yellow */
    color: #000; /* Text color for contrast */
  }

  .grade-button:hover {
    transform: scale(1.05);
    background-color: #f0f4c3; /* Slightly darker yellow on hover */
  }
  .book-image {
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    object-fit: cover; /* Ensures the image fits within the defined size */
  }
  .book-card {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 10px;
    transition: transform 0.3s;
  }
  .book-card:hover {
    transform: scale(1.05);
  }
  @media (max-width: 768px) {
    .book-card {
      width: 45%; /* 2 columns on small screens */
    }
  }
  @media (max-width: 576px) {
    .book-card {
      width: 100%; /* Full width on mobile */
    }
  }
  .school-icon {
    font-size: 2rem; /* Adjust icon size */
    color: #007bff; /* Icon color */
    margin-right: 10px;
  }
  .school-card {
    transition: transform 0.2s, box-shadow 0.2s;
    border: none;
    border-radius: 5px;
    padding: 5px;
  }
  
  .school-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  
  .school-title {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .school-location {
    font-size: 0.9rem;
    color: #6c757d;
  }
 