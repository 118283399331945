/* AdminDashboard.css */

body {
    font-family: 'Arial', sans-serif;
    background-color: #f8f9fa;
    margin: 0;
    padding: 0;
}

.admin-sidebar-container {
    display: flex;
}

.admin-sidebar-container .menu-toggle {
    display: none; /* Hide on larger screens */
    font-size: 24px;
    background-color: #ffc107;
    border: none;
    padding: 10px;
    cursor: pointer;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1000; /* Ensure button is on top */
}

.navSidebar {
    width: 250px;
    background-color: #343a40;
    color: #fff;
    padding: 20px;
    position: fixed;
    top: 0;
    left: -250px; /* Initially hidden off-screen */
    height: 100%; /* Full height */
    transition: left 0.3s ease; /* Smooth transition for sidebar */
    z-index: 999; /* Ensure it appears on top */
}

.navSidebar.active {
    left: 0; /* Show the sidebar */
}

.navSidebar h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #ffc107;
}

.navSidebar nav ul {
    list-style: none;
    padding: 0;
}

.navSidebar nav ul li {
    margin-bottom: 15px;
}

.navSidebar nav ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    display: flex;
    align-items: center;
}

.navSidebar nav ul li a:hover {
    color: #ffc107;
}

.navSidebar nav ul li a svg {
    margin-right: 10px;
}

.content {
    flex: 1;
    padding: 20px;
    background-color: #ffffff;
    overflow-y: auto;
}
.admin-sidebar-container .menu-toggle {
    display: block; /* Show menu toggle button on small screens */
}

@media (max-width: 768px) {
    .admin-sidebar-container .menu-toggle {
        display: block; /* Show menu toggle button on small screens */
    }

    .navSidebar {
        width: 250px;
        
    }

    .content {
        padding: 10px;
    }
}
