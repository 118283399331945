/* General Styles */
.logo {
  max-height: 50px;
}

/* Hero Section Styles */
.section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh; /* Full screen height */
  background: linear-gradient(to right, #0052cc, #00c6ff);
  color: white;
  text-align: center;
  border-radius: 12px;
  overflow: hidden;
}


.section h1 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: capitalize;
  animation: fadeInDown 1s ease-in-out;
}

.section p {
  font-size: 1.25rem;
  margin-bottom: 10px;
  line-height: 1.6;
  max-width: 600px;
  animation: fadeInUp 1.2s ease-in-out;
}

.section .btn {
  padding: 12px 40px;
  font-size: 1.25rem;
  font-weight: bold;
  background-color: #ffeb3b;
  color: #0052cc;
  border: none;
  border-radius: 50px;
  transition: background-color 0.3s ease;
  animation: bounceIn 1.5s ease;
  margin-right: 10px;
}

.section .btn:hover {
  background-color: #ffd600;
}

/* Media Query for Mobile Responsiveness */
@media (max-width: 768px) {
  .section {
    
    padding: 0px 0;
  }

  .section h1 {
    font-size: 2rem;
  }

  .section p {
    font-size: 1rem;
  }

  .section .btn {
    font-size: 1rem;
    padding: 10px 30px;
  }
}

/* Animations */
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounceIn {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}


/* Navigation Styles */
.bottom-nav {
  display: none; /* Hide by default; will be shown on small screens */
}

/* Fixed bottom navigation bar for small screens */
@media (max-width: 768px) {
  .bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f8f9fa;
    border-top: 1px solid #ddd;
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
    z-index: 1000;
  }

  .bottom-nav .nav-link {
    color: #000;
    text-align: center;
    font-size: 14px;
  }

  .bottom-nav .nav-link .icon {
    display: block;
    font-size: 20px;
  }
}

/* Back to School Items - Large Screens */
.back-to-school-items {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

/* Style each back-to-school item */
.back-to-school-items .item {
  text-align: center;
  flex: 0 0 calc(35 - 20px); /* 3 items per row for large screens */
  max-width: 200px; /* Optional: limit max width */
  box-sizing: border-box;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.item-image {
  width: 100%;
  height: 200px; /* Fixed height for large screens */
  object-fit: cover;
  border-radius: 8px;
}

/* Adjustments for Small Screens */
@media (max-width: 768px) {
  .back-to-school-items .item .school-item{
    flex: 0 0 calc(33.333% - 1rem); /* 2 items per row for tablets and phones */
    max-width: 100%; /* Ensure full width */
  }

  .item-image {
    height: 150px; /* Smaller height for small screens */
  }
}

/* Style for the Featured Products section */
.featured-books {
  display: flex;
  overflow-x: auto; /* Allows horizontal scrolling if needed */
  gap: 10px; /* Adds spacing between items */
  margin-top: 20px;
  padding: 10px 0; /* Optional: adds padding to the top and bottom */
}

.featured-books .image-set {
  text-align: center;
  flex: 0 0 calc(33.333% - 20px); /* 3 items per row for large screens */
  max-width: 200px; /* Sets a maximum width for each item */
}

.featured-image {
  width: 100%;
  height: auto; /* Maintains aspect ratio */
  object-fit: cover;
  border-radius: 4px;
}

/* Floating Buttons Styles */
@media (max-width: 768px) {
  .floating-buttons {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    background-color: #ffffff;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }

  .floating-buttons .btn {
    margin-right: 10px;
  }
}

/* Additional Styles */
.nus {
  background-color: #e6e6fa;
  border-radius: 8px; /* Optional: for rounded corners */
}

/* Responsive Grid for Categories */
.categories {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive grid */
  grid-gap: 20px;
}
.common-schools {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Adjust spacing between items */
  padding: 20px;
}

.school-item {
  
  align-items: center;
  padding: 20px;
  border: 2px solid #00ff2a; /* Border color */
  border-radius: 10px;
  background-color: #f8f9fa; /* Background color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  transition: transform 0.3s, box-shadow 0.3s;
  width: calc(30% - 10px); /* Two items per row minus the gap */
  text-align: center;
  box-sizing: border-box; /* Ensure padding and border are included in the width */
}

.school-item:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.school-icon {
  font-size: 2rem; /* Adjust icon size */
  color: #007bff; /* Icon color */
  margin-right: 10px;
}

.school-item h3 {
  margin: 0;
  font-size: 1.25rem; /* Adjust font size */
  color: #333; /* Text color */
}

/* Responsive styling */
@media (max-width: 768px) { /* Tablets and phones */
  .school-item {
    width: 20%;
    flex: 0 0 calc(33% - 1rem); /* Full width for smaller screens */
  }.item {
    width: 100%;
    flex: 0 0 calc(50% - 1rem); /* Full width for smaller screens */
  }
}



  .sidebar {
    position: fixed;
    top: 56px; /* Place the sidebar just below the header */
    right: -250px; /* Initially hidden */
    width: 250px;
    transition: right 0.3s ease; /* Smooth slide-in animation */
    z-index: 1000;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1); /* Shadow for better visibility */
  }
  
  /* Open state for the sidebar on mobile */
  .sidebar.open {
    right: 0; /* Slide in the sidebar */
    height:calc(100vh - 56px - 60px);
    background-color: #fff;
    
  }
  
  /* Sidebar link styling */
  .sidebar .nav-link {
    color: #2e2c2c;
    padding: 0.5rem 0;
    text-decoration: none;
  }
  
  /* Hover effect for sidebar links */
  .sidebar .nav-link:hover {
    background-color: #f0f0f0; /* Light gray hover effect */
    cursor: pointer;
  }
  
  /* Bottom navigation styling for mobile devices */
  .bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px; /* Adjust the height to match your design */
    background-color: #f8f9fa; /* Background color of the bottom navigation */
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow */
    z-index: 1100; /* Ensure it stays above other elements */
  }
