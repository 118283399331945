.container {
  display: flex;
  flex-direction: column;
  /* Ensure the container takes up full viewport height */
}

main {
  flex: 1; /* Allow main content to expand and push footer down */
  padding: 20px;
  text-align: center; /* Center the content horizontally */
}

h1, h3 {
  margin-bottom: 10px;
}

.grade-selection, .option-selection, .upload-section, .pick-section {
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  justify-content: center; /* Center buttons horizontally */
  gap: 10px; /* Add spacing between buttons */
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none; /* Remove default button border */
  background-color: #4CAF50; /* Example color, adjust as needed */
  color: white;
  border-radius: 5px; /* Add rounded corners */
}

select {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}


/* Responsive design adjustments */
