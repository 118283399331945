/* Mobile-specific styling */
@media (max-width: 768px) {
  /* Sidebar styling for mobile devices */
  .sidebar {
    position: fixed;
    top: 56px; /* Place the sidebar just below the header */
    right: -250px; /* Initially hidden */
    width: 250px;
    transition: right 0.3s ease; /* Smooth slide-in animation */
    z-index: 1000;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1); /* Shadow for better visibility */
  }
  
  /* Open state for the sidebar on mobile */
  .sidebar.open {
    right: 0; /* Slide in the sidebar */
    height:calc(100vh - 56px - 60px);
    background-color: #fff;
    
  }
  
  /* Sidebar link styling */
  .sidebar .nav-link {
    color: #2e2c2c;
    padding: 0.5rem 0;
    text-decoration: none;
  }
  
  /* Hover effect for sidebar links */
  .sidebar .nav-link:hover {
    background-color: #f0f0f0; /* Light gray hover effect */
    cursor: pointer;
  }
  
  /* Bottom navigation styling for mobile devices */
  .bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px; /* Adjust the height to match your design */
    background-color: #f8f9fa; /* Background color of the bottom navigation */
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow */
    z-index: 1100; /* Ensure it stays above other elements */
  }
}
.sidebar {
  position: fixed;
  top: 56px; /* Place the sidebar just below the header */
  right: -250px; /* Initially hidden */
  width: 250px;
  transition: right 0.3s ease; /* Smooth slide-in animation */
  z-index: 1000;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1); /* Shadow for better visibility */
}

/* Open state for the sidebar on mobile */
.sidebar.open {
  right: 0; /* Slide in the sidebar */
  height:calc(100vh - 56px - 60px);
  background-color: #fff;
  
}

/* Sidebar link styling */
.sidebar .nav-link {
  color: #2e2c2c;
  padding: 0.5rem 0;
  text-decoration: none;
}

/* Hover effect for sidebar links */
.sidebar .nav-link:hover {
  background-color: #f0f0f0; /* Light gray hover effect */
  cursor: pointer;
}

/* Bottom navigation styling for mobile devices */
.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px; /* Adjust the height to match your design */
  background-color: #f8f9fa; /* Background color of the bottom navigation */
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow */
  z-index: 1100; /* Ensure it stays above other elements */
}
.sidebar-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sidebar-footer {
  margin-top: auto; /* Pushes the logout link closer to the bottom */
  padding: 1rem 0; /* Add some padding to provide space between the link and the edge */
  margin-bottom: 60px; /* Control how far the Logout link is from the bottom */
  border-top: 1px solid #ddd; /* Optional border to separate it from the rest of the links */
}
