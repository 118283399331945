.hero-section {
  background-size: cover;
  background-position: center;
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  overflow: hidden;
}
.hero-content {
  text-align: center;
  z-index: 2;
}
.hero-content h1 {
  font-size: 3rem;
  font-weight: bold;
  color: #9bdd00;
  margin: 0 0 1rem;
  animation: fadeIn 1.5s ease-out;
}
.exciting-button {
  background: linear-gradient(90deg, #ff7e5f, #feb47b);
  color: rgb(3, 3, 3);
  font-weight: bold;
  font-size: 1.1rem;
  border: none;
  border-radius: 5px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cta-button {
  background-color: #28a745; /* Green background */
  color: white; /* White text */
  border: none; /* Remove border */
  border-radius: 25px; /* Rounded corners */
  padding: 15px 30px; /* Top-bottom and left-right padding */
  font-size: 18px; /* Font size */
  font-weight: bold; /* Bold text */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Shadow effect */
}

.cta-button:hover {
  background-color: #218838; /* Darker green on hover */
  transform: translateY(-3px); /* Slightly lift the button */
}

.cta-button:active {
  transform: translateY(1px); /* Push down effect on click */
}

.custom-add-button {
  background: linear-gradient(90deg, #ffca5f, #ba7bfe);
  color: rgb(6, 6, 6);
  font-weight: bold;
  font-size: 1.1rem;
  border: none;
  border-radius: 5px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;  /* Smooth transition effects */
}

.custom-add-button:hover {
  color: rgb(6, 6, 6);
  transform: scale(1.05);  /* Slightly enlarges button on hover */
}




.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  animation: fadeIn 2s ease-out;
}



.cta-button:hover {
  background-color: #ff5733;
  transform: scale(1.1);
}

.bottom-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.bottom-nav a {
  padding: 15px;
  display: inline-block;
  width: 33%;
  text-align: center;
  color: #333;
}

.bottom-nav a:hover {
  background-color: #f0f0f0;
}
.book-card {
  width: 150px;
  text-align: center;
}

.book-card {
  width: 150px;
  text-align: center;
}

.book-image {
  width: 150px;
  height: 190px;
  object-fit: cover;
}

.book-card span {
  font-size: 16px; /* Default size */
}

.book-card .text-end strong {
  font-size: 18px; /* Default size for price */
}
.custom-search-input {
  width: 250px; /* Reduced width */
  padding: 10px; /* Add some padding */
  font-size: 14px; /* Slightly smaller text */
  border-radius: 20px; /* Rounded corners */
  border: 2px solid #9bdd00; /* Add a border color */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Slight shadow */
  transition: all 0.3s ease; /* Smooth transition for hover effect */
}

.custom-search-input:focus {
  outline: none;
  border-color: #88f405; /* Border color change on focus */
}

.custom-search-button {
  background: linear-gradient(90deg, #ff7b54, #ff5733); /* Gradient background */
  border: none;
  padding: 10px 20px;
  color: white;
  border-radius: 20px; /* Rounded button */
  transition: background 0.3s ease;
}

.custom-search-button:hover {
  background: linear-gradient(90deg, #ff5733, #ff7b54); /* Reverse the gradient on hover */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); /* Add hover shadow */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/* Image styling in the table */
.table-book-image {
  width: 40px; /* Set a fixed width */
  height: 60px; /* Set a fixed height to ensure consistency */
  object-fit: cover; /* Ensure the image covers the entire area without stretching */
  border-radius: 4px; /* Optional: Add a small border radius for better visuals */
  border: 1px solid #ddd; /* Optional: Add a light border around the image */
}
