.background-radial-gradient {
    background-color: hsl(218, 41%, 15%);
    background-image: radial-gradient(650px circle at 0% 0%,
        hsl(218, 41%, 35%) 15%,
        hsl(218, 41%, 30%) 35%,
        hsl(218, 41%, 20%) 75%,
        hsl(218, 41%, 19%) 80%,
        transparent 100%),
      radial-gradient(1250px circle at 100% 100%,
        hsl(218, 41%, 45%) 15%,
        hsl(218, 41%, 30%) 35%,
        hsl(218, 41%, 20%) 75%,
        hsl(218, 41%, 19%) 80%,
        transparent 100%);
  }
  
  #radius-shape-1 {
    height: 220px;
    width: 220px;
    top: -50px;
    left: -180px;
    background: radial-gradient(#44006b, #ad1fff);
    overflow: hidden;
  }
  
  #radius-shape-2 {
    border-radius: 38% 62% 63% 37% / 70% 33% 67% 30%;
    bottom: -60px;
    right: -310px;
    width: 300px;
    height: 300px;
    background: radial-gradient(#44006b, #ad1fff);
    overflow: hidden;
  }
  
  .bg-glass {
    background-color: hsla(0, 0%, 100%, 0.9) !important;
    backdrop-filter: saturate(200%) blur(25px);
  }
  .white-label .form-label {
    color: #ffffff;
  }
  .white-label {
    color: white; /* Make the label text white */
  }
  .checkout-button {
    background-color: #28a745; /* Ensures it uses Bootstrap's success green */
    border-color: #28a745; /* Same as background color */
    color: white; /* Text color */
    font-size: 1.2rem; /* Slightly larger text */
    font-weight: 600; /* Makes the text bold */
    padding: 10px 20px; /* Increases the padding for a bigger button */
    border-radius: 8px; /* Rounds the corners more */
    transition: background-color 0.3s ease-in-out, transform 0.2s ease;
  }